export enum WorkflowStep {
  Initial = 'initial',
  Psychologist = 'psychologist',
  ChildhoodAge = 'childhood_age',
  Category = 'category',
  Requests = 'requests',
  Contacts = 'contacts',
  Finished = 'finished',
  TelegramRequested = 'telegram_requested',
}

export type Workflow = {
  id: string,
  currentState: WorkflowStep,
  dealId: string,
  requests: string[],
}
